<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <a-form-model-item label="角色名称" prop="name">
            <a-input v-model="form.name" placeholder="输入角色名称" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="权限范围" prop="original_visible_ids">
            <select-book type="book" multiple v-model="form.original_visible_ids"></select-book>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="数据权限" prop="table_ids">
            <a-collapse v-model="activeKey">
              <a-collapse-panel v-for="system in systemList" :key="system.value">
                <template v-slot:header>
                  <a-space>
                    <a-checkbox
                      :indeterminate="system.indeterminate"
                      :checked="system.checkAll"
                      :disabled="system.list.length == 0"
                      @change="onCheckAllChange($event, system)"
                      @click.stop
                    >
                    </a-checkbox>
                    <span>{{ system.label }}</span>
                  </a-space>
                </template>
                <a-checkbox-group
                  v-if="system.list.length > 0"
                  v-model="system.checkedList"
                  :options="system.list"
                  @change="onChange($event, system)"
                />
                <div class="empty-box" v-else>暂无数据</div>
              </a-collapse-panel>
            </a-collapse>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { systemOptions } from "../../../common/core/type";
import { clone } from "../../../common/js/tool";

export default {
  name: "RoleForm",

  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        original_visible_ids: [{ required: true, message: "请选择权限范围", trigger: "change" }],
        table_ids: [{ required: true, message: "请选择数据权限", trigger: "change" }],
      },
      activeKey: systemOptions.map((item) => item.value.toString()),
      systemList: systemOptions.map((item) => ({
        ...item,
        value: item.value.toString(),
        list: [],
        checkedList: [],
        checkAll: false,
        indeterminate: false,
      })),
    };
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$axios({
        url: "/admin/data-table",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 99999,
        },
      }).then((res) => {
        let list = res.data;
        list.forEach((item) => {
          let type = item.type;
          if (type) {
            let system = this.systemList[type - 1];
            system.list.push({ ...item, label: item.name, value: item.id.toString() });
          }
        });
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        if (form.original_visible_ids) {
          form.original_visible_ids = JSON.parse(form.original_visible_ids);
        }
        if (form.table) {
          form.table.forEach((item) => {
            let type = item.type;
            if (type) {
              let system = this.systemList[type - 1];
              system.checkedList.push(item.id.toString());
              let checkLength = system.checkedList.length;
              let listLength = system.list.length;
              if (checkLength == listLength) {
                system.checkAll = true;
                system.indeterminate = false;
              } else {
                system.indeterminate = true;
              }
            }
          });
        }
        this.form = form;
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            let original_visible_ids = form.original_visible_ids;
            if (original_visible_ids) {
              let visible_ids = {
                uids: [],
                dept: [],
                tag: [],
              };
              original_visible_ids?.forEach((item) => {
                if (item.type == "department") {
                  visible_ids.dept.push(item.id);
                } else if (item.type == "user") {
                  visible_ids.uids.push(item.id);
                } else if (item.type == "tag") {
                  visible_ids.tag.push(item.id);
                }
              });
              form.visible_ids = visible_ids;
              form.original_visible_ids = JSON.stringify(original_visible_ids);
            }
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
    updateTableIds() {
      this.$set(this.form, "table_ids", "");
      this.systemList.forEach((system) => {
        if (system.checkedList.length > 0) {
          let table_ids = this.form.table_ids;
          this.form.table_ids += (table_ids ? "," : "") + system.checkedList.join(",");
        }
      });
      this.$refs.ruleForm.validateField("table_ids");
    },
    // 全选
    onCheckAllChange(e, system) {
      let checked = e.target.checked;
      system.checkAll = checked;
      system.indeterminate = false;
      if (checked) {
        system.checkedList = system.list.map((item) => item.value);
      } else {
        system.checkedList = [];
      }
      this.updateTableIds();
    },
    // 单个选择
    onChange(checkedValue, system) {
      if (checkedValue.length == 0) {
        system.indeterminate = false;
        system.checkAll = false;
        this.updateTableIds();
        return;
      }
      if (checkedValue.length == system.list.length) {
        system.indeterminate = false;
        system.checkAll = true;
      } else {
        system.indeterminate = true;
        system.checkAll = false;
      }
      this.updateTableIds();
    },
  },
};
</script>

<style lang="less" scoped>
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.ant-checkbox-group {
  padding-left: 45px;
  /deep/ &-item {
    padding: 4px 0;
  }
}

.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
