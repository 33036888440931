// 系统列表
export const systemOptions = [
  { label: "服务协同系统", value: 1 },
  { label: "人事评价系统", value: 2 },
  { label: "佛事法会系统", value: 3 },
  { label: "资产管理系统", value: 4 },
  { label: "安全管理系统", value: 5 },
  { label: "走进灵隐系统", value: 6 },
  { label: "供灯管理系统", value: 7 },
  { label: "智能空间系统", value: 8 },
];
